import React from "react"
import Layout from "../components/layout"
import {
  Badge,
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Spacer,
  Text,
  UnorderedList,
  ListItem,
  Image,
} from "@chakra-ui/react"
import anne from "../media/matte.svg"
import { Link } from "gatsby"

const schedule = [

/*
  {
    title: "Relaxing and Restore",
    location: "Bölschestraße 69A , 12587 Berlin",
    date: "Do, 15.08.24 , 20:00- 21:15",
    link: "https://ensostudio.de/kursplan-friedrichshagen",
    ensoyoga: true,
     },
    {
      title: "Relaxing and Restore",
      location: "Bölschestraße 69A , 12587 Berlin",
      date: "Do, 22.08.24 , 20:00 - 21:15",
      link: "https://ensostudio.de/kursplan-friedrichshagen",
      ensoyoga: true,
       },       
*/
];

export default function Home() {
  return (
    <Layout title="Dates" slug="courses/">
      {schedule.length > 0 && (
        <Heading as="h2" size="md" mb={3}>
        Save-the-Date
        </Heading>
      )}
      {schedule.map(event => (
        <Box borderWidth="1px" borderRadius="lg" overflow="hidden" mb={3}>
          <Box p="5">
            <Box d="flex" alignItems="baseline">
              {event.online && (
                <Badge borderRadius="full" px="2" colorScheme="yellow">
                  Online Livestream
                </Badge>
              )}
              {event.yogibar && (
                <Badge borderRadius="full" px="2" colorScheme="green">
                  Yogibar
                </Badge>
              )}
              {event.retreat && (
                <Badge borderRadius="full" px="2" colorScheme="blue">
                  Retreat
                </Badge>
              )}
              {event.ensoyoga && (
                <Badge borderRadius="full" px="2" colorScheme="pink">
                  EnsoYoga
                </Badge>
              )}
              <Box
                color="gray.500"
                letterSpacing="wide"
                fontSize="sm"
                textTransform="uppercase"
                ml="2"
              >
                {event.date}
              </Box>
            </Box>
            <Flex>
              <Box >
                <Text mt="1" as="h4" fontSize="lg" lineHeight="tight" isTruncated>
                  {event.title}
                </Text>
                <Text mt="1" as="h4" fontSize="sm" lineHeight="tight" isTruncated>
                  {event.location}
                </Text>
              </Box>
              <Spacer />
              <Box>
                <Link to={event.link}>
                  <Button colorScheme="green" size="sm" variant="outline">
                    Mehr
                  </Button>
                </Link>
              </Box>
            </Flex>
          </Box>
        </Box>
      ))}
      {schedule.length > 0 && <Box mb={20}></Box>}
      <Heading as="h2" size="md" mb={3}>
        Yoga auf Anfrage
      </Heading>
      <Text>
        {" "}
        Auf Anfrage biete ich Yogastunden per Online Livestream an.
      </Text>
      <Flex mt={12} display={["block", "flex"]}>
        <Box>
          <Heading as="h3" size="sm" mb={3}>
            Yoga für Gruppen ab 3 Personen:
          </Heading>

          <UnorderedList mb={3}>
            <ListItem>Hatha Yoga 60 Minuten 8,00 €/ Teilnehmer</ListItem>
            <ListItem>Restorative 60 Minuten 8,00 €/ Teilnehmer</ListItem>
          </UnorderedList>

          <Heading as="h3" size="sm" mt={12} mb={3}>
            Yoga als Einzelstunde:
          </Heading>

          <UnorderedList mb={6}>
            <ListItem>Hatha Yoga 60 Minuten 29,00 €</ListItem>
            <ListItem>Restorative 60 Minuten 29,00 €</ListItem>
          </UnorderedList>
        </Box>
        <Spacer />
        <Center>
          <Box boxSize="2xs">
            <Image src={anne} alt="Illustration von einer Yogamatte" />
          </Box>
        </Center>
        <Spacer />
      </Flex>

      <Text>
        Eine Einzelstunde wird auf individuelle Bedürfnisse angepasst und kann
        aus einer Kombination von Hatha- und Restorative Yoga bestehen.
        Individuelles wird in einem Erstgespräch geklärt.
      </Text>

      <Heading as="h3" size="sm" mt={12} mb={3}>
        Wie funktioniert Online Livestream Yoga?
      </Heading>
      <Text>
        Nachdem ein Yogatermin vereinbart wurde, schicke ich einen Link zu einem
        virtuellen Yogaraum. Das Programm Jitsi Meet ist webbasiert, daher wird
        lediglich ein internetfähiges Gerät benötigt, z.B. Laptop, Smartphone,
        Tablet etc.
        <br />
        Eine kurze E-Mail mit einem Wunschtermin zur Anmeldung ist ausreichend.
        <br />
      </Text>
    </Layout>
  )
}
